// imports
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';

// lib files
import { getBestStationWhiteLogo } from '@/lib/helpers/get-best-station-white-logo';
import { navigationAtom, NavigationStateEnum } from '@/lib/atoms/navigation';
import { StationData } from "@/lib/types/api/stations-data";
import { useOutsideClick } from '@/lib/hooks/useOutsideClick';

// components
import DonateMenuItem from '@/components/Navigation/DonateMenuItem';
import ITSImage from '@/components/ITSImage/ITSImage';
import MobileMenu from '@/components/Navigation/MobileMenu';
import MyListMenuItem from '@/components/Navigation/MyListMenuItem';
import NavLink from '@/components/Navigation/NavLink';
import SignInOrProfile from '@/components/Navigation/SignInOrProfile';
import SearchMenu from '@/components/Navigation/SearchMenu';
import StationMenu from '@/components/Navigation/StationMenu';

// svgs
import LiveTVEllipse from '/public/svg/ellipse.svg';
import MyStationIcon from '/public/svg/my-station-white.svg';
import PBSLogotype from '/public/svg/pbs-logotype-white--blue-fill-face.svg';
import ShowsIcon from '/public/svg/shows-white.svg';

// styles
import styles from '@/components/Navigation/UtilityNav.module.scss';
interface UtilityNavProps {
  stationData?: StationData;
  isSVP: boolean;
  isUS: boolean;
  atTop: boolean;
  includeAmericaAt250?: boolean;
}
const getActiveClassName = (currentPathname: string, linkUrl: string): string => {
  return currentPathname.startsWith(linkUrl) ? styles.utility_nav_link_active : '';
};
const UtilityNav = (props: UtilityNavProps) => {
  const {
    stationData,
    isSVP,
    isUS,
    atTop,
    includeAmericaAt250
  } = props;
  const [navigation, setNavigation] = useAtom(navigationAtom);
  const isLocalized = stationData?.id;
  const className = atTop ? `${styles.utility_nav}` : `${styles.utility_nav} ${styles.utility_nav__scrolled}`;
  const pathname = usePathname();

  // this closes the menu on any path change (e.g. navigation)
  // regardless of it's in the menu or on the page
  useEffect(() => {
    setNavigation(NavigationStateEnum.Default);
  }, [pathname, setNavigation]);

  // The following bit provides refs for the various
  // sub menus so we can close them when clicking outside any of them
  const closeMenus = () => {
    setNavigation(NavigationStateEnum.Default);
  };
  const searchMenuRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const stationMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const refs = [searchMenuRef, mobileMenuRef, stationMenuRef, userMenuRef];
  useOutsideClick(refs, closeMenus);
  let homeLinkClassName = `${styles.home_link}`;
  if (isSVP) {
    homeLinkClassName += ` ${styles.svp_home_link}`;
  }
  const stationLogo = stationData && getBestStationWhiteLogo(stationData.attributes.images);
  return <nav className={className} data-sentry-component="UtilityNav" data-sentry-source-file="UtilityNav.tsx">
      <NavLink href={'/'} className={homeLinkClassName} data-sentry-element="NavLink" data-sentry-source-file="UtilityNav.tsx">
        {isSVP && stationLogo ? <ITSImage src={stationLogo} alt={stationData?.attributes?.short_common_name || 'PBS'} loading="eager" width={170} className={styles.svp_station_logo} /> : <PBSLogotype className={styles.pbs_head_logo} />}
      </NavLink>

      <ul className={styles.utility_nav_links}>
        {isUS && isLocalized && <li className={getActiveClassName(pathname, "/livestream/")}>
            <NavLink href="/livestream/">
              <LiveTVEllipse className={styles.live_tv_icon} />
              Live TV
            </NavLink>
          </li>}

        <li className={getActiveClassName(pathname, "/shows/")}>
          <NavLink href="/shows/" data-sentry-element="NavLink" data-sentry-source-file="UtilityNav.tsx">
            <ShowsIcon className={styles.shows_icon} data-sentry-element="ShowsIcon" data-sentry-source-file="UtilityNav.tsx" />
            Shows
          </NavLink>
        </li>

        {!isSVP && isLocalized && <li className={getActiveClassName(pathname, "/my-station/")}>
            <NavLink href="/my-station/">
              <MyStationIcon className={styles.my_station_icon} />
              My Station
            </NavLink>
          </li>}

        <MyListMenuItem className={getActiveClassName(pathname, "/my-list/shows/")} data-sentry-element="MyListMenuItem" data-sentry-source-file="UtilityNav.tsx" />

        {stationData?.attributes.donate_url && <DonateMenuItem stationData={stationData} className={styles.push} />}
      </ul>

      <StationMenu ref={stationMenuRef} stationData={stationData} isSVP={isSVP} data-sentry-element="StationMenu" data-sentry-source-file="UtilityNav.tsx" />

      <SignInOrProfile ref={userMenuRef} isSVP={isSVP} stationData={stationData} data-sentry-element="SignInOrProfile" data-sentry-source-file="UtilityNav.tsx" />

      <SearchMenu ref={searchMenuRef} data-sentry-element="SearchMenu" data-sentry-source-file="UtilityNav.tsx" />

      <MobileMenu ref={mobileMenuRef} stationData={stationData} isUS={isUS} isSVP={isSVP} includeAmericaAt250={includeAmericaAt250} data-sentry-element="MobileMenu" data-sentry-source-file="UtilityNav.tsx" />
    </nav>;
};
export default UtilityNav;