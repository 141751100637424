// imports
import Link from 'next/link';
import { usePathname } from 'next/navigation';

// data
import { contentNavLinks } from '@/components/Navigation/nav-data';

// svgs
import PassportCompass from '/public/svg/compass-rose.svg';

// styles
import styles from '@/components/Navigation/ContentNav.module.scss';
import AllGenresMenu from './AllGenresMenu';
interface PropType {
  isSVP: boolean;
  atTop: boolean;
  scrollDirection: 'up' | 'down';
  includeAmericaAt250?: boolean;
}
const ContentNav = (props: PropType) => {
  const {
    isSVP,
    atTop,
    scrollDirection,
    includeAmericaAt250
  } = props;
  let style = `${styles.content_nav}`;
  if (!atTop && scrollDirection === 'down') {
    style += ` ${styles.content_nav__scrolled}`;
  }
  const pathname = usePathname();
  const links = contentNavLinks({
    isSVP
  });
  const filteredLinks = links.filter(link => link.hasOwnProperty('condition') ? link.condition : true);
  return <nav className={style} data-sentry-component="ContentNav" data-sentry-source-file="ContentNav.tsx">
      <ul className={styles.content_nav_list}>
        {filteredLinks.map((link, index) => {
        let linkClassNames = styles.content_nav_link;
        if (pathname === link.href) {
          linkClassNames += ` ${styles.content_nav_link__active}`;
        }
        return <li key={index} className={styles.content_nav_item}>
              {link.include_passport_icon && <PassportCompass className={styles.passport_compass} />}
              <Link href={link.href} className={linkClassNames}>
                {link.label}
              </Link>
            </li>;
      })}
        <li key={filteredLinks.length} className={`${styles.all_genres_nav_item}`}>
          <div className={styles.content_nav_link}>
            All Genres +
          </div>

          <AllGenresMenu className={`${styles.all_genres_menu}`} includeAmericaAt250={includeAmericaAt250} data-sentry-element="AllGenresMenu" data-sentry-source-file="ContentNav.tsx" />
        </li>
      </ul>
    </nav>;
};
export default ContentNav;