// imports
import { useAtom } from 'jotai';
import { useState, forwardRef, ForwardedRef } from 'react';
import Link from 'next/link';

// lib files
import { handleSignOut } from '@/lib/helpers/handle-sign-out';
import { navigationAtom, NavigationStateEnum } from '@/lib/atoms/navigation';
import { ORIGIN } from '@/lib/constants';
import { ProfileData } from '@/lib/types/api/profile-data';
import { setRedirectCookie } from '@/lib/helpers/utils';
import { StationData } from "@/lib/types/api/stations-data";
import { useHasMounted } from '@/lib/hooks';
import { userProfile } from '@/lib/atoms/profile';
// @TODO clean up this prop after launch
import { ExploreTopicLabelEnum, ExploreTopicRouteEnum } from "@/lib/constants";

// components
import MobileMenuButton from '@/components/Navigation/MobileMenuButton';
import NavLink from '@/components/Navigation/NavLink';
import SignInButton from '@/components/Navigation/SignInButton';
import UserImageInitial from '@/components/Navigation/UserImageInitial';

// data
import { mobileGenreLinks } from '@/components/Navigation/nav-data';

// svgs
import BackIcon from '/public/svg/caret-prev.svg';
import DonateIcon from '/public/svg/donate-heart.svg';
import GearIcon from '/public/svg/gear.svg';
import GenresIcon from '/public/svg/genres.svg';
import LiveTVEllipse from '/public/svg/ellipse.svg';
import MyListIcon from '/public/svg/my-list-check-white.svg';
import MyStationIcon from '/public/svg/my-station-white.svg';
import PassportCompass from '/public/svg/compass-rose.svg';
import ShowsIcon from '/public/svg/shows-white.svg';

// styles
import styles from './MobileMenu.module.scss';
interface MobileMenuGenresProps {
  onClick: () => void;
  // @TODO clean up this prop after launch
  includeAmericaAt250?: boolean;
}
const MobileMenuGenres = (props: MobileMenuGenresProps) => {
  const {
    onClick,
    includeAmericaAt250
  } = props;

  // @TODO clean this up after launch - it won't be needed once the update to nav-data.ts is done
  if (includeAmericaAt250) {
    const featuredCivicsIndex = mobileGenreLinks.findIndex(link => link.label === ExploreTopicLabelEnum.Civics);
    if (featuredCivicsIndex !== -1) {
      mobileGenreLinks[featuredCivicsIndex] = {
        label: ExploreTopicLabelEnum.AmericaAt250,
        href: ExploreTopicRouteEnum.AmericaAt250
      };
    }
  }
  return <ul className={styles.genre_links} data-sentry-component="MobileMenuGenres" data-sentry-source-file="MobileMenu.tsx">
      {mobileGenreLinks.map((genre, index) => <li key={index}>
          <NavLink href={genre.href} className={styles.mobile_menu_genre_nav_link} onClick={onClick}>
            {genre.passport && <PassportCompass className={styles.passport_compass} />}
            {genre.label}
          </NavLink>
        </li>)}
    </ul>;
};
interface MobileMenuProfileProps {
  profile: ProfileData;
}
const MobileMenuProfile = (props: MobileMenuProfileProps) => {
  const {
    profile
  } = props;
  return <div className={styles.mobile_menu_profile} data-sentry-component="MobileMenuProfile" data-sentry-source-file="MobileMenu.tsx">
      <UserImageInitial profile={profile} data-sentry-element="UserImageInitial" data-sentry-source-file="MobileMenu.tsx" />

      <div className={styles.name_sign_out}>
        <a href={`${ORIGIN}/sso/profile-link`} onClick={() => setRedirectCookie()} className={styles.profile_name}>
          {profile.profile?.first_name} {profile.profile?.last_name}
        </a>

        <a href={`${ORIGIN}/sso/logout`} onClick={handleSignOut} className={styles.sign_out}>
          Sign Out
        </a>
      </div>

      <NavLink href="/settings" aria-label="Settings" data-sentry-element="NavLink" data-sentry-source-file="MobileMenu.tsx">
        <GearIcon className={styles.gear_icon} data-sentry-element="GearIcon" data-sentry-source-file="MobileMenu.tsx" />
      </NavLink>
    </div>;
};
interface MobileMenuProps {
  profileStub?: ProfileData | null;
  stationData?: StationData;
  depIsOpen?: boolean;
  isUS: boolean;
  isSVP: boolean;
  // @TODO clean up this prop after launch
  includeAmericaAt250?: boolean;
}
const MobileMenu = forwardRef(function MobileMenu(props: MobileMenuProps, ref: ForwardedRef<HTMLDivElement>) {
  const {
    profileStub,
    depIsOpen,
    stationData,
    isUS,
    isSVP,
    includeAmericaAt250
  } = props;
  const [navigation, setNavigation] = useAtom(navigationAtom);
  const [profile, setProfile] = useAtom(userProfile);
  const [genresOpen, setGenresOpen] = useState(false);
  const isOpen = depIsOpen || navigation === NavigationStateEnum.MobileMenuOpen;
  const donateUrl = stationData?.attributes.donate_url;
  const isLocalized = stationData?.id;
  const genreClickHandler = () => {
    setNavigation(NavigationStateEnum.Default);
  };
  const hasMounted = useHasMounted();

  // this weird block of code is really here for the storybook
  // we only pass `null` if we want to show the logged out state
  if (profileStub === null) {
    setProfile(null);
  } else if (profileStub) {
    setProfile(profileStub);
  }
  let classNames = `${styles.mobile_menu}`;
  if (isOpen) {
    classNames += ` ${styles.is_open}`;
  }
  if (genresOpen) {
    classNames += ` ${styles.genres_open}`;
  }
  return <div className={styles.mobile_menu_wrapper} ref={ref}>
      <MobileMenuButton />
      <nav className={classNames}>
        <div className={styles.mobile_menu_inner}>
        <div className={styles.genres_toggle}>
            {genresOpen ? <button onClick={() => setGenresOpen(!genresOpen)} aria-label="Close Genres Menu" className={styles.back_button} role="button">
                <BackIcon />
              </button> : <GenresIcon className={styles.genres_icon} />}

            <button onClick={() => setGenresOpen(!genresOpen)} className={styles.genres_button} role="button">
            Genres
            </button>
        </div>

        <MobileMenuGenres onClick={genreClickHandler} includeAmericaAt250={includeAmericaAt250} />

        <ul className={styles.links}>
          {isUS && isLocalized && <li>
              <NavLink href="/livestream/">
                <LiveTVEllipse className={styles.live_tv_icon} />
                Live TV
              </NavLink>
            </li>}
          <li>
            <NavLink href="/shows/">
              <ShowsIcon className={styles.shows_icon} />
              Shows
            </NavLink>
          </li>
          {!isSVP && isLocalized && <li>
            <NavLink href="/my-station/">
              <MyStationIcon className={styles.my_station_icon} />
              My Station
            </NavLink>
          </li>}
          <li>
            <NavLink href="/my-list/shows/">
              <MyListIcon className={styles.my_list_icon} />
              My List
            </NavLink>
          </li>

          {donateUrl && <li className={styles.push}>
            <Link href={donateUrl} className={styles.donate_link} prefetch={false}>
              <DonateIcon className={styles.donate_icon} />
              Donate
            </Link>
          </li>}

          <li className={styles.personal}>
            {profile && hasMounted ? <MobileMenuProfile profile={profile} /> : <SignInButton className={styles.sign_in} size="max" />}
          </li>
        </ul>
        </div>
      </nav>
    </div>;
});
export default MobileMenu;